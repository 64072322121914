.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  color: #333;
}

.not-found-title {
  font-size: 6rem;
  margin-bottom: 1rem;
}

.not-found-message {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.not-found-link {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found-link:hover {
  background-color: #0056b3;
}
