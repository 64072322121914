@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  outline: none !important;
  user-select: none;
}
body {
  background: #e5e5e5;
}

.custom-toast {
  font-size: 14px;
}

.carousel-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.show-more {
  font-weight: bold;
  width: auto;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.ql-editor {
  min-height: 80vh !important;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}
.tag-icon {
  padding: 3px 10px;
  font-size: 15px; /* Điều chỉnh kích thước icon tại đây */
}

// #textContentDetail {
// }
